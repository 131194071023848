@import 'tailwindcss/utilities';

@import '@jouzen/ecom-components/dist/index.css';

html,
body {
  scroll-behavior: smooth;
  @media (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

html.nojs .react-multi-carousel-track {
  height: 100% !important;
  width: 100% !important;
  overflow-x: auto !important;
}
